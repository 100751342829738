<template>
    <DropdownTree label="Category" v-model:searchStr="searchStr" :tree="realTree" :path="path"
        @selectKeyPath="selectKeyPath" @gotoKeyPath="gotoKeyPath" />
</template>

<script setup>
import { computed, defineProps, defineEmit, ref, toRef, watch } from "vue";
import { useStore } from "vuex";
import de from "@/utils/DeepEquals";
import DropdownTree from "./DropdownTree.vue";

// TODO: Call these from DropdownTree when they can be exposed in Vue 3.2 
const getChild = (tree, childKey) =>
    (tree.children ?? []).filter(item => de(item.key, childKey))[0] ?? null

const getAtPath = (tree, path) => {
    if (path.length == 0 || tree === null) return tree
    return getAtPath(getChild(tree, path[0]), path.slice(1))
}
// Translate category objects we get from the server into what is expected in the category dropdown
// by the display component.
const stateCatToNode = stateCat => {
    if (stateCat == null) return null

    else return {
        key: { parentId: stateCat.parent?.id, id: stateCat.id },
        value: stateCat.label.name,
        children: (stateCat.children ?? []).map(stateCatToNode)
    }
}
const treeSearchCatToNode = (parentId, id, obj) => {
    return {
        key: { parentId, id },
        value: obj.label,
        children: Object.entries(obj.children ?? {})
            .map(([c_id, c_obj]) => treeSearchCatToNode(id, c_id, c_obj)),
    }
}
const indexWhere = (array, predicate) => {
    for (let i = 0; i < array.length; i++)
        if (predicate(array[i])) return i;

    return -1
}

const props = defineProps({
    label: String,
    // The current selected value, has id, name and parentId
    modelValue: Object,
    // The current path, a list of the keys we can follow in the tree to reach the selected item.
    // These keys are the ones from stateCatToNode so they should have parentId as the first
    // property and their own id as the second property.
    path: Array,
})
const emit = defineEmit("update", "update:modelValue", "update:path")
const store = useStore()

store.dispatch("fetchTopLevelCategories")

const searchStr = ref("")
const searchTree = ref(null)
watch(searchStr, async newSearch => {
    if (newSearch === "") {
        searchTree.value = null
        return
    }

    const tree = await store.dispatch("getCategoryTreeSearch", newSearch)
    const children = Object.entries(tree).map(([id, obj]) => treeSearchCatToNode(null, id, obj))
    searchTree.value = {
        key: "ROOT",
        value: "",
        selectable: false,
        children,
    }
})

const current = toRef(props, "modelValue")
const tree = computed(() => {
    // Initialize the root of the tree and the items that always need to be loaded
    const root = {
        key: "ROOT",
        value: "",
        selectable: false,
        children: []
    }
    root.children = store.getters.getTopCategories
        .map(cat => ({ ...cat, children: [] }))
        .map(stateCatToNode)

    // Add all the items on the path to the tree so that the user sees what they have open
    let parent = root
    for (const key of props.path) {
        const newNode = stateCatToNode(store.getters.getCategory(key.id))
        if (newNode == null) break
        const newNodeIndex = indexWhere(parent.children, obj => obj.key.id == key.id)
        parent.children[newNodeIndex] = newNode
        parent = newNode
    }

    return root
})
const realTree = computed(() => searchTree.value ?? tree.value)

// Initialize the path if needed
for (const key of props.path)
    store.dispatch("fetchCategory", key.id)

const gotoKeyPath = (path) => {
    emit("update:path", path)
    const option = getAtPath(realTree.value, path)
    const newCurrent = { id: option.key.id, name: option.value, parentId: option.key.parentId }
    emit("update:modelValue", newCurrent)
    if (newCurrent.id != null)
        store.dispatch("fetchCategory", newCurrent.id)
}
const selectKeyPath = () => {
    emit("update", current.value)
}
</script>
