<template>
    <div @click="dropdownHidden = false" v-click-outside="onClickOutside" class="relative">
        <div>
            <label :for="`dropdownTreeInput_${id}`" class="block text-sm font-medium text-gray-700 dark:text-white">
                {{ CapitalizeWords(props.label) }}
            </label>
            <div class="mt-1 rounded-md shadow-sm flex"
                @mousedown.prevent="dropdownSearchInput ? dropdownSearchInput.focus() : ''">
                <input :value="inputBoxValue" @input="$event.target.value = inputBoxValue" type="text"
                    name="dropdownTreeInput" :id="`dropdownTreeInput_${id}`" autocomplete="off"
                    class="cursor-pointer focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
            </div>
        </div>
        <div v-show="!dropdownHidden"
            class="absolute w-full bg-primary dark:bg-primaryDark border-gray-300 dark:border-gray-500 border rounded-md z-10">
            <div class="grid grid-flow-row">
                <input v-if="searchStr !== null" :value="searchStr" ref="dropdownSearchInput"
                    class="cursor-pointer focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" type="text"
                    @input="$emit('update:searchStr', $event.target.value)"/>
                <div class="grid grid-flow-col">
                    <TreeDisplay :node="tree" :path="[]" :currPath="path" @gotoKeyPath="gotoKeyPath"
                        @selectKeyPath="selectNewPath($event)" @close="dropdownHidden = true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmit, ref, computed, watch, nextTick } from "vue"
import de from "@/utils/DeepEquals"
import CapitalizeWords from "@/utils/CapitalizeWords"
import TreeDisplay from "./DropdownTree/TreeDisplay.vue"
import useUniqueComponentId from "@/utils/reactive/useUniqueComponentId"


// Pure Functions
const getChild = (tree, childKey) =>
    (tree.children ?? []).filter(item => de(item.key, childKey))[0] ?? null

const getAtPath = (tree, path) => {
    if (path.length == 0 || tree === null) return tree
    return getAtPath(getChild(tree, path[0]), path.slice(1))
}
// Navigate up the tree and return a list of the names the function encounters on the way
const treeKeysToValues = (tree, path) => {
    if (path.length == 0 || tree === null) return []
    const child = getChild(tree, path[0])
    if (child == null) return treeKeysToValues(child, path.slice(1))
    else return [child.value].concat(treeKeysToValues(child, path.slice(1)))
}

// Component Settings
const props = defineProps({
    label: String,
    // The tree structure to navigate, detailed example below
    tree: Object,
    // The key values along the tree that will lead you to the currently selected node by the user
    path: Array,
    // The current search string, if this is null the search is disabled and not shown to the user
    searchStr: {
        type: String,
        required: false,
        default: null,
    },
})
const emit = defineEmit(
    // The user is visiting this node in the tree, if more data needs to be loaded it needs to
    // happen now as the user will possibly want to visit that next.
    "gotoKeyPath",
    // The user wants to select this path and they are not navigating further, currently this will
    // always be called after a gotoKeyPath on said path
    "selectKeyPath",
    "update:searchStr",
)
const id = useUniqueComponentId()

// State Variables
const dropdownSearchInput = ref(null)
const dropdownHidden = ref(true)
// The dropdown tree shouldn't try to follow the selected path when searching
const currentPath = computed(() => props.searchStr ? [] : props.path)

const inputBoxValue = computed(() => {
    const currNode = getAtPath(props.tree, currentPath.value)
    const currNodeSelectable = (currNode?.selectable ?? true) === true
    if (!currNodeSelectable) return ""
    else return CapitalizeWords(treeKeysToValues(props.tree, currentPath.value).join(" > "))
})

const clearSearch = () => {
    if (props.searchStr)
        emit("update:searchStr", "")
}
// Functions with mutation
const selectNewPath = newPath => {
    emit("gotoKeyPath", newPath)
    emit("selectKeyPath", newPath)
    dropdownHidden.value = true
    clearSearch()
}
const onClickOutside = () => {
    dropdownHidden.value = true
    const target = getAtPath(props.tree, currentPath.value)
    if ((target?.selectable ?? true) === true) emit("selectKeyPath", currentPath.value)
    clearSearch()
}
const gotoKeyPath = newPath => {
    dropdownHidden.value = false
    emit("gotoKeyPath", newPath)
    clearSearch()
}

// Select the search box when the dropdown is un-hidden
watch(dropdownHidden, async hidden => {
    await nextTick()
    if (!hidden && dropdownSearchInput.value)
        dropdownSearchInput.value.focus()
})

/*  Example of a valid tree input:

    const tree = {
        key: "ROOT",
        value: "",
        selectable: false, // Optional, defaults to true
        children: [ // Optional, defaults to an empty array
            {
                key: 15,
                value: "Útiföt",
                selectable: false,
                children: [
                    {
                        key: 11,
                        value: "Vesti",
                        children: [
                            { key: 7, value: "Spari Vesti" },
                            { key: 8, value: "Her Vesti" },
                            { key: 9, value: "Úti Vesti" }
                        ]
                    },
                    { key: 17, value: "Annað" }
                ]
            },
            {
                key: 14,
                value: "Inniföt",
                selectable: false,
                children: [
                    { key: 10, value: "Vesti" }
                ]
            },
        ]
    }
*/
</script>
