<template>
    <div class="mt-10 lg:mx-56 sm:mx-10 mx-4">
        <form @submit.prevent="onSubmit">
            <div class="">
                <div class="bg-primary rounded-lg py-6 px-4 space-y-6 sm:p-6 dark:bg-primaryDark">
                    <div class>
                        <h2 class="text-2xl font-semibold text-gray-900 dark:text-white">{{ title }}</h2>
                        <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">This information will be displayed
                            publicly so be careful what you share.</p>
                    </div>
                    <div class="dark:text-white">
                        <div class="">

                            <MediaImageDragAndDropField class="w-full" @onChange="receiveImages"
                                :album="PhotoAlbumNames.Photos" />

                            <MediaImageGrid class=" w-full " :images="images" @delete="deleteImage" />
                            <div class="md:flex gap-5 mt-5">
                            <SmallTextInput heading="Name" class="w-full sm:w-4/12  gap-2" :value="state.name"
                                :errors="v$.name.$errors" @update:value="state.name=$event" />

                            <SelectInput heading="Status" class="w-full sm:w-4/12 mt-2 sm:mt-0 gap-2 sm:gap-0" :options="statusOptions"
                                :value="state.status" :errors="v$.status.$errors" @update:value="state.status=$event" />

                            <SelectInput heading="Acquisition" class="w-full sm:w-4/12 mt-2 sm:mt-0 gap-2 sm:gap-0" :options="acquisitionOptions"
                                :value="state.acquisition" :errors="v$.acquisition.$errors"
                                @update:value="state.acquisition=$event" />
                            </div>    
                            <div class="mt-5 w-full">
                            <LargeTextInput heading="Description" :value="state.description"
                                :errors="v$.description.$errors" @update:value="state.description=$event" />

                            </div>
                            <div class="md:flex gap-5 mt-5">
                            <SmallIntInput heading="Weight" class="w-full sm:w-4/12  gap-2"   :value="state.weight" :errors="v$.weight.$errors"
                                @update:value="state.weight=$event" />

                            <SmallTextInput heading="Material" class="w-full sm:w-4/12 mt-2 sm:mt-0 gap-2 sm:gap-0" :value="state.material" :errors="v$.material.$errors"
                                @update:value="state.material=$event" />

                            <SmallIntInput heading="Value" class="w-full sm:w-4/12 mt-2 sm:mt-0 gap-2 sm:gap-0" :value="state.value" :errors="v$.value.$errors"
                                @update:value="state.value=$event" />
                            </div>
                            <div class="md:flex gap-5 mt-5">
                            <Checkbox heading="Modifications allowed " class="col-span-3 gap-2"
                                :value="state.modificationsAllowed" @update:value="state.modificationsAllowed=$event" />
                            </div>
                            <div class="md:flex gap-5 mt-5"> 
                            <SelectInput heading="Place" class="w-full sm:w-4/12  gap-1" :options="places" v-model:value="placeId" />

                            <SelectInput heading="Space" class="w-full sm:w-4/12 mt-2 sm:mt-0 gap-1 sm:gap-0" :options="spaces" v-model:value="spaceId" />

                            <ParentSpaceStorageDropdownTree label="Storage" class="w-full sm:w-4/12 mt-2 sm:mt-0 gap-2 sm:gap-0" :spaceId="spaceId"
                                v-model="currentStorage" v-model:path="storagePath"
                                @update="state.storageId = $event.id" />
                            </div>
                            <div class="col-span-3 mt-5">
                                <p class="text-sm font-medium text-gray-700 dark:text-white">Recommended tags</p> 
                                <div class="flex flex-wrap flex-row gap-2 w-full">
                                    <div v-for="tag in notSelectedRecommendedTags" :key="tag"
                                        @click="state.tags += state.tags === '' ? `${tag}` : `;${tag}`"
                                        class="px-2 py-0.5 border bg-gray-200 text-gray-800 text-sm rounded line-clamp-1 cursor-pointer">
                                        {{ tag }} 
                                    </div>
                                </div>
                            </div>

                            <Multiselect heading="Tags" searchPlaceholder="Tag to create..." class="mt-3"
                                :createActive="true" :options="[]" :modelValue="pySplit(';', state.tags)"
                                @update:modelValue="state.tags = $event.join(';')" />

                        </div>
                        <template v-for="_, idx in state.categories" :key="idx">
                            <div class="mt-3">
                                <ThingCategoryDropdownTree label="Category"
                                    v-model="state.categories[idx]" v-model:path=categoriesPath[idx] />
                            </div>
                        </template>
                        <VuelidateErrorDisplay :errors="v$.categories.$errors" />
                    </div>
                    <div>
                        <h3 class="text-2xl mt-6 font-semibold text-gray-900 dark:text-white">Special Properties</h3>
                        <p class="mt-1 text-sm text-gray-500 dark:text-gray-300">These properties are specific to the
                            category of your thing so they change if you change your things category.</p>
                    </div>
                    <div class="grid grid-cols-2 gap-6 dark:text-white">
                        <div v-for="prop in customProperties" :key="prop.id" class="col-span-2 sm:col-span-1 self-center">
                            <SmallTextInput v-if="prop.type === 'STRING'" :heading="prop.name"
                                :value="customPropertyValues[prop.id] ?? ''"
                                @update:value="customPropertyValues[prop.id]=$event" />

                            <!-- TODO: Add int verification -->
                            <SmallIntInput v-else-if="prop.type === 'INT'" :heading="prop.name" type="number"
                                :value="customPropertyValues[prop.id] ?? ''"
                                @update:value="customPropertyValues[prop.id]=$event" />

                            <Multiselect v-else-if="prop.type === 'OPTION_M'" :heading="prop.name"
                                :options="prop.options" :modelValue="customPropertyValues[prop.id] ?? []"
                                @update:modelValue="customPropertyValues[prop.id]=$event" />

                            <SelectInput v-else-if="prop.type === 'OPTION_S'" :heading="prop.name"
                                :options="prop.options" :value="customPropertyValues[prop.id] ?? ''"
                                @update:value="customPropertyValues[prop.id]=$event" />

                            <div v-else-if="prop.type === 'BOOLEAN'">
                                <!-- <label :for="`custom_bool_${prop.id}`"
                                    class="block text-sm font-medium text-gray-700 dark:text-white">{{ prop.name
                                    }}</label>
                                <input type="checkbox" :id="`custom_bool_${prop.id}`"
                                    v-model="customPropertyValues[prop.id]" /> -->
                                <!-- TODO:  This needs further testing -->
                                    <Checkbox :heading="prop.name" :value="customPropertyValues[prop.id] === 'true'" @update:value="customPropertyValues[prop.id]=$event.toString()"/>
                            </div>

                            <div v-else-if="prop.type === 'TABLE'">
                                <label>{{prop.name}}</label>
                                <SelectInput heading="Format" :options="reverseKeyVal(arrayToObj(prop.table[0] ?? []))"
                                    :value="customPropertyExtras[prop.id] ?? 0"
                                    @update:value="customPropertyExtras[prop.id]=$event" />
                                <SelectInput heading="Value"
                                    :options="getSelectInputCol(prop.table, customPropertyExtras[prop.id] ?? 0)"
                                    :value="customPropertyValues[prop.id]"
                                    @update:value="customPropertyValues[prop.id]=$event" />
                            </div>

                            <p v-else> Unknown custom property type '{{ prop.type }}'. Please contact Showdeck's
                                developers so that they can fix this issue.</p>

                            <!-- <div v-if="prop.type === 'INT'" class="mt-1 rounded-md shadow-sm flex">
                                <input :value="customPropertyValues[prop.id] ?? ''" :id="'custom_prop_' + prop.id"
                                    @input="customPropertyValues[prop.id] = $event.target.value" type="text"
                                    class="focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300 dark:bg-primaryDark" />
                            </div> -->
                        </div>
                    </div>
               
                <div class="px-4 py-3  text-right sm:px-6 dark:bg-primaryDark">
                    <button type="submit"
                        class="inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save</button>
                </div>
            </div>
            </div>
        </form>
    </div>
</template>

<script setup>
import { computed, defineProps, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VuelidateErrorDisplay from "../../widgets/VuelidateErrorDisplay.vue";
import ThingCategoryDropdownTree from "../../inputs/ThingCategoryDropdownTree.vue";
import CapitalizeWords from "../../../utils/CapitalizeWords";
import SmallTextInput from '@/components/inputs/SmallTextInput'
import LargeTextInput from '@/components/inputs/LargeTextInput'
import SelectInput from '@/components/inputs/SelectInput'
import MediaImageDragAndDropField from '@/components/widgets/mediaImageWidget/MediaImageDragAndDropField'
import MediaImageGrid from '@/components/widgets/mediaImageWidget/MediaImageGrid'
import { PhotoAlbumNames } from '@/constants.js'
import Multiselect from '@/components/inputs/Multiselect'
import SmallIntInput from "../../inputs/SmallIntInput.vue"
import Checkbox from "../../inputs/Checkbox.vue";
import capitalize from "../../../utils/Capitalize";
import ParentSpaceStorageDropdownTree from "../../inputs/ParentSpaceStorageDropdownTree.vue";
import pySplit from "@/utils/PySplit";

// Pure functions

const arrayToObj = arr => {
    const entries = arr.map((val, index) => [index, val])
    return Object.fromEntries(entries)
}

const reverseKeyVal = obj => {
    const entries = Object
        .entries(obj)
        .map(([key, val]) => [val, key])

    return Object.fromEntries(entries)
}

const getCol = (array2d, colIndex) => array2d.map(row => row[colIndex])

const getSelectInputCol = (table, colNum) => {
    const column = colNum ? getCol(table, colNum) : []
    const valuesNoHeader = column.slice(1)
    const reversedColumnObj = reverseKeyVal(arrayToObj(valuesNoHeader))
    return reversedColumnObj
}

const props = defineProps({
    title: String,
    startData: Object,
    editThingId: String,
})
const store = useStore()
const router = useRouter()
store.dispatch("fetchThingStatusOptions")
store.dispatch("fetchThingAcquisitionOptions")
store.dispatch("fetchUserVenueNames")

// Data form
const state = ref({
    name: props.startData?.basic?.name ?? "",
    categories: props.startData?.basic?.categories ?? [{ id: 0, name: "Root", parentId: 0 }],
    description: props.startData?.basic?.description ?? "",
    status: capitalize(props.startData?.basic?.status ?? ""),
    weight: props.startData?.basic?.weight ?? "",
    material: props.startData?.basic?.material ?? "",
    value: props.startData?.basic?.value ?? "",
    modificationsAllowed: props.startData?.basic?.modificationsAllowed ?? true,
    acquisition: capitalize(props.startData?.basic?.acquisition ?? ""),
    storageId: props.startData?.basic?.storageId ?? "",
    tags: props.startData?.basic?.tags ?? "",
})
const rules = {
    name: { required },
    categories: { required },
    description: { required },
    status: { required },
    weight: { required },
    material: { required },
    value: { required },
    acquisition: { required },
}
const v$ = useVuelidate(rules, state, { $lazy: true })

// Refs / Getters
const currentStorage = ref(props.startData?.extra?.locCurrent ?? { id: 0, name: "", type: "" })
const storagePath = ref(props.startData?.extra?.locPath ?? [])
const placeId = ref(props.startData?.extra?.placeId ?? null)
const spaceId = ref(props.startData?.extra?.spaceId ?? null)

if (placeId.value !== null)
    store.dispatch("fetchPlaceSpaceStorageChildren", { type: "venue", id: placeId.value })
watch(placeId, newId => {
    // Reset the things below it
    spaceId.value = null
    state.value.storageId = ""

    // Fetch the spaces in the selected place
    if (newId === null) return
    store.dispatch("fetchPlaceSpaceStorageChildren", { type: "venue", id: newId })
})
watch(spaceId, () => {
    // Reset the things below it
    state.value.storageId = ""
})

const places = computed(() => Object.fromEntries(
    store.getters.getUserVenueNames
    .map(p => [p.name, p.id])
))
const spaces = computed(() => Object.fromEntries(
    store.getters.getVenueSpaces(placeId.value)
    .map(s => [s.name, s.id])
))

const categoriesPath = ref((props.startData?.basic?.categories ?? []).map(cat => cat.path))
if (categoriesPath.value?.length === 0)
    categoriesPath.value = [[]]
const customPropertyValues = ref(props.startData?.custom ?? {})
const customPropertyExtras = ref({})

const allImages = ref({
    prev: props.startData?.images ?? [],
    new: [],
    toDel: [],
})
const images = computed(() => [...allImages.value.prev, ...allImages.value.new])
const recommendedTags = computed(() => {
    const newImageTags = allImages.value.new
        .map(img => store.getters.getImageTags(img) ?? [])
        .flat(1)
    return [...(new Set(newImageTags))]
})
const notSelectedRecommendedTags = computed(() => {
    const selected = new Set(pySplit(";", state.value.tags.toLowerCase()))
    return recommendedTags.value.filter(tag => !selected.has(tag.toLowerCase()))
})

const statusOptions = computed(() => store.getters.getThingStatusOptions)
const acquisitionOptions = computed(() => store.getters.getThingAcquisitionOptions)
const customProperties = computed(() => store.getters
    .getCategoryCustomProperties(state.value.categories?.[0]?.id)
    .map(prop => ({
        ...prop,
        name: CapitalizeWords(prop.name),
        type: prop.propertyType,
        options: (prop.options ?? []).map(CapitalizeWords)
    })))
const onCategoryChange = () => {
    if (state.value.categories?.[0]?.id)
        store.dispatch("fetchCategoryCustomProperties", state.value.categories[0]?.id)
}
onCategoryChange()
watch(() => state.value.categories?.[0]?.id, onCategoryChange)

// Load tags when the images change
watch(allImages.value, () => {
    store.dispatch("getTagsForNewImages", allImages.value.new)
})

// Functions
const submitImages = id => {
    // Add the new images
    const newImages = allImages.value.new.map(i =>
        store.dispatch("createMediaImage", { ...i, thingId: id }))

    // Remove the toDel images
    const delImage = async params => {
        const img = await store.dispatch("editMediaImage", params)
        if (img == null) {
            throw new Error("Failed to delete image")
        }
    }
    const removePromises = allImages.value.toDel.map(i =>
        delImage({ uuid: i.uuid, delete: true }))
        

    return Promise.all([...newImages, ...removePromises])
}

const onSubmit = async () => {
    v$.value.$touch()
    if (v$.value.$error) return

    const sendState = { ...state.value, categories: null, categoryId: state.value.categories[0].id }
    const customProps = Object
        .entries(customPropertyValues.value)
        .map(([id, value]) => ({ id, value: Array.isArray(value) ? value.join(";") : value }))

    let newThing
    if (props.editThingId == null)
        newThing = await store.dispatch("createThing", { ...sendState, customProps })
    else newThing = await store.dispatch("editThing", { id: props.editThingId, ...sendState, customProps })

    await submitImages(newThing.id)
    await router.push({ name: 'Thing', params: { id: newThing.id } })
}

const receiveImages = files => {
    allImages.value.new.push(...files)
}

const deleteImage = idx => {
    if (idx < allImages.value.prev.length) {
        allImages.value.toDel.push(allImages.value.prev[idx])
        allImages.value.prev.splice(idx, 1)
    } else {
        allImages.value.new.splice(idx - allImages.value.prev.length, 1)
    }
}
</script>
