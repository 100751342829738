<template>
  <Popover v-if="display" class="relative z-20 p-2 w-96 h-64 shadow rounded flex justify-center items-center">
      <PopoverPanel static >
          <div class="relative">
              <div v-if="image.meta.hasError" >
                  <p class="text-black p-2 bg-gray-100 ">{{errorMsg}}</p>
              </div>
              <img v-else :src="image.preview" class="rounded" />
            <TrashIcon @click="$emit('delete')" class="absolute cursor-pointer w-10 bg-gray-100 text-gray-900 top-0 right-0" />
          </div>
      </PopoverPanel>
  </Popover>
</template>

<script>
import { computed, } from 'vue'
import { Popover, PopoverPanel } from '@headlessui/vue'
import { ImageIsToLargeMsg, ImageIsWrongFormatMsg } from '@/constants'
import { TrashIcon } from '@heroicons/vue/outline/esm'

export default {
    name: "MediaImageGridHoverWindow",
    components: { Popover, PopoverPanel, TrashIcon },
    props: {
        image: {
            type: Object,
            required: true
        },
        display: {
            type: Boolean,
            required: false
        }
    },
    setup(props) {
        
        return {
            errorMsg: computed(() => {
                const meta = props.image.meta  
                if (meta.fileToBig) return ImageIsToLargeMsg
                if (!meta.formatAllowed) return ImageIsWrongFormatMsg
                return ""
            })
        }
        
    }

}
</script>
