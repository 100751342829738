<template>
    <div 
        class="cursor-pointer relative object-cover h-full w-auto"
        :class="[image.meta.hasError ? 'border-red-500 border-2 ' : 'border']"
        @mouseenter="hoverActive = true"
        @mouseleave="hoverActive = false"
        >

        <div 
            v-if="image.meta.hasError" 
            class="absolute animate-pulse opacity-50 w-full h-full bg-red-800 z-10"  
        />
            <div class="bg-red-200 w-full h-full overflow-hidden">
                <img :src="image.preview" class="relative min-h-full object-cover"/>
            </div>
            <MediaImageGridHoverWindow @delete="$emit('delete')" :image="image" :display="hoverActive" />
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { ref } from 'vue'
import MediaImageGridHoverWindow from '@/components/widgets/mediaImageWidget/MediaImageGridHoverWindow'

export default {
    name: "MediaImageGridCell",
    components: { MediaImageGridHoverWindow },
    props: {
        image: {
           type: Object,
           required: true
        }
    },
    setup() {
        const hoverActive = ref(false)
        return { hoverActive }
    }
}
</script>
