<template>
    <DropdownTree label="Storage" :tree="tree" :path="props.path" @gotoKeyPath="gotoKeyPath" @selectKeyPath="selectKeyPath" />
</template>

<script setup>
import { computed, watch, defineProps, defineEmit } from "vue";
import { useStore } from "vuex";
import DropdownTree from "./DropdownTree.vue";
import de from "@/utils/DeepEquals";

// Pure functions

const stateStorageToNode = stateCat => {
    if (stateCat == null) return null

    else return {
        key: { id: stateCat.id },
        value: stateCat.name,
        children: (stateCat.children ?? []).map(stateStorageToNode)
    }
}
const indexWhere = (array, predicate) => {
    for (let i = 0; i < array.length; i++)
        if (predicate(array[i])) return i;

    return -1
}
// TODO: Call these from DropdownTree when they can be exposed in Vue 3.2 
const getChild = (tree, childKey) =>
    (tree.children ?? []).filter(item => de(item.key, childKey))[0] ?? null

const getAtPath = (tree, path) => {
    if (path.length == 0 || tree === null) return tree
    return getAtPath(getChild(tree, path[0]), path.slice(1))
}

const props = defineProps({
    label: String,
    modelValue: Object,
    path: Array,
    spaceId: String,
})
const emit = defineEmit("update", "update:modelValue", "update:path")
const store = useStore()

// Rebuild the dropdown tree every time the data underneath it changes
const tree = computed(() => {
    const root = {
        key: "ROOT",
        value: "",
        selectable: false,
        children: []
    }
    // Add the top level options that should always be loaded
    root.children = store.getters.getSpaceStorages(props.spaceId)
        .map(item => ({ ...item, children: [] }))
        .map(stateStorageToNode)
    console.log("props.spaceId", props.spaceId)
    console.log("root.children", root.children)
    
    // Go through the selected keys and make sure all of their children are loaded
    let parent = root
    for (const key of props.path) {
        const newParentIndex = indexWhere(parent.children, obj => obj.key.id == key.id)
        const newParent = parent.children[newParentIndex]
        if (newParent == null) break

        newParent.children = store.getters.getStorageStorages(key.id)
            .map(stateStorageToNode)
        parent = newParent
    }

    return root
})

// Initialize the path if needed
for (const key of props.path)
    store.dispatch("fetchPlaceSpaceStorageChildren", { id: key.id, type: "storage" })

const onSpaceChange = () => {
    const newSpace = store.getters.getVenueSpaces(props.spaceId)
    const current = { id: props.spaceId, name: newSpace?.name ?? "Unknown", type: "space" }
    // Make sure we have the data for the new space in the cache so that the getters in the tree
    // builder can fetch it from there
    store.dispatch("fetchPlaceSpaceStorageChildren", current)
}
const gotoKeyPath = path => {
    const node = getAtPath(tree.value, path)
    const newCurrent = { id: node.key.id, name: node.value, type: "storage" }
    emit("update:path", path)
    emit("update:modelValue", newCurrent)
    // Fetch the data about the new storage and put it into the cache
    if (newCurrent.id != null)
        store.dispatch("fetchPlaceSpaceStorageChildren", newCurrent)
}
const selectKeyPath = () => emit("update", props.modelValue)

// Change the dropdown options every time the space ID changes
if (props.spaceId) onSpaceChange()
watch(() => props.spaceId, () => onSpaceChange())
</script>
