<template>
    <div class="container grid gap-4 items-center "
         :class="gridCols"
    >
        <div v-for="img, idx in images" 
             class="col-span-1 w-32 h-32 object-cover rounded"
            :key="idx">
            
            <MediaImageGridCell @delete="$emit('delete', idx)" :image="img" />

        </div>
    </div> 
</template>

<script>
import MediaImageGridCell from '@/components/widgets/mediaImageWidget/MediaImageGridCell'

export default {
    name: "MediaImageGrid",
    components: { MediaImageGridCell },
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    setup() {

        //let gridCols = computed(() => `grid-cols-${props.images.length > 5 ? 5 : props.images.length}`)
        let gridCols = 'grid-cols-4 md:grid-cols-5 lg:grid-cols-6'

        return {
            gridCols,
        }
    }
}
</script>
