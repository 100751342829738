/**
 * Check if two items are mostly equal, uses == for the base case so "20" and 20 would for example
 * result in true even though they're not exactly the same.
 *
 * This function recursively searches 
 * objects and arrays so those do not need to have the same address but instead just the same
 * content.
 */
const deepEquals = (x, y) => {
    if (typeof x !== typeof y) return false
    
    if (Array.isArray(x)) {
        if (x.length !== y.length) return false

        for (let i = 0; i < x.length; i++) {
            if (!deepEquals(x[i], y[i])) return false
        }
        return true
    }
    else if (typeof x === "object" && x !== null) {
        const sorted_x = Object.entries(x).sort()
        const sorted_y = Object.entries(y).sort()
        if (sorted_x.length !== sorted_y.length) return false

        for (let i = 0; i < sorted_x.length; i++) {
            const [x_key, x_val] = sorted_x[i]
            const [y_key, y_val] = sorted_y[i]
            if (!deepEquals(x_key, y_key)) return false
            if (!deepEquals(x_val, y_val)) return false
        }
        return true
    }
    else return x == y
}

export default deepEquals

