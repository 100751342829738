<template>
    <div class="py-0.5">
        <div v-if="selectable" class="flex flex-row h-min">
            <div class="pr-1 h-min flex items-center cursor-pointer">
                <!-- NOTE:  The rotation of the icon below should be
                            animated but for some reason it rotates
                            instantly.
                -->
                <div class="flex items-center justify-center text-gray-600 hover:bg-gray-200 dark-hover:bg-gray-200 w-6 h-6 rounded-full transition"
                    :class="[(children.length === 0 && !isCurrPath) ? '' : 'transform rotate-90']"
                    @click.stop="(children.length === 0 && !isCurrPath) ? $emit('gotoKeyPath', path) : $emit('gotoKeyPath', path.slice(0, -1))">
                    <font-awesome-icon icon="fa-solid fa-chevron-right self-center" class="" fixed-width />
                </div>
            </div>
            <div @click.stop="$emit('selectKeyPath', path)" class="ml-2 cursor-pointer hover:bg-gray-200 dark-hover:text-gray-600 px-1 rounded">
            <p>
                {{ CapitalizeWords(node.value) }}
            </p>
            </div>
        </div>
        <div :class="`flex flex-col ${selectable ? 'pl-6' : 'pl-2'}`">
            <TreeDisplay v-for="child in children" :key="child.key" :node="child" :path="[...path, child.key]"
                :currPath="currPath" @gotoKeyPath="$emit('gotoKeyPath', $event)"
                @selectKeyPath="$emit('selectKeyPath', $event)" />
            <div v-if="children.length === 0 && isCurrPath">
                <p class="ml-4 text-gray-500">
                    No more items
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineEmit, defineProps } from "vue"
import de from "@/utils/DeepEquals"
import CapitalizeWords from "../../../utils/CapitalizeWords";
const props = defineProps({ node: Object, path: Array, currPath: Array })
defineEmit("gotoKeyPath", "selectKeyPath")

const children = computed(() => props.node.children ?? [])
const isCurrPath = computed(() => de(props.path, props.currPath))
const selectable = computed(() => props.node.selectable ?? true)
</script>
