<template>
    <SmallTextInput :heading="props.heading" :value="props.value" :errors="props.errors" :version="props.version"
        :placeholder="props.placeholder" :tooltip="props.tooltip" :focus="props.focus" type="tel"
        :acceptKeydown="isEvtAllowed" @update:value="valueUpdate" />
</template>

<script setup>
import { defineEmit, defineProps } from 'vue';
import SmallTextInput from './SmallTextInput.vue'

const props = defineProps({
    heading: String,
    value: [String, Number],
    errors: Array,
    version: String,
    placeholder: String,
    tooltip: {
        type: Boolean,
        default: undefined
    },
    focus: {
        type: Boolean,
        default: undefined
    }
})
const emit = defineEmit("update:value")

const valueUpdate = newValue => {
    // Make sure we don't prevent the user from erasing everything
    if (newValue === "") {
        emit("update:value", null)
        return
    }

    // Parse the int and skip emitting it if it isn't an actual number
    const parsed = parseInt(newValue)
    if (!isNaN(parsed)) emit("update:value", parsed)
    else emit("update:value", props.value)
}

const isControlKey = evt => {
    switch (evt.key) {
        // Regular keys
        case "Backspace":
        case "Enter":
        case "ArrowLeft":
        case "ArrowRight":
        case "ArrowUp":
        case "ArrowDown":
        case "Home":
        case "End":
        case "Tab":
            return true
        // Keys allowed with control
        case "a":
        case "x":
        case "c":
            return evt.ctrlKey
        default:
            return false
    }
}
const isEvtAllowed = evt => {
    console.log("evt.key", evt.key)
    console.log("evt", evt)
    return !isNaN(evt.key) || isControlKey(evt)
}
</script>
