<template>
  <div class="flex flex-row justify-between items-center">
        <p v-if="heading" class="text-sm font-medium text-gray-700 dark:text-white  "> {{heading}} </p>
        <div
            class="h-5 w-5 border cursor-pointer rounded-md border-gray-400 bg-gray-100 flex justify-center items-center dark:bg-black"

            @click="$emit('update:value', !value)"
            >
            <font-awesome-icon v-if="value" icon="fa-solid fa-check" class="" size="sm"/>
        </div>
  </div>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        value: Boolean,
        heading: String,
    },
}
</script>

